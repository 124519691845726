export default class Router {
  constructor() {
    this.controllers = new Map()

    window.addEventListener('DOMContentLoaded', () => this.onDOMContentLoaded())
  }

  loadMethod(method) {
    const pathname = this.sanitize(location.pathname)

    if (typeof method === 'function') {
      const result = method()

      if (result.then) {
        result.then((module) => {
          if (module && module.default) {
            module.default()
          }
        })
      }
    } else if (method) {
      console.log('Router loading ', { pathname, method })
      throw new Error('Router unknown handler for' + pathname + method)
    } else {
      console.warn('Router no register for ' + pathname)
    }
  }

  onDOMContentLoaded() {
    const pathname = this.sanitize(location.pathname)

    const method = this.controllers.get(pathname)

    if (method) {
      this.loadMethod(method)
    }
  }

  on(pathname, method) {
    this.controllers.set(this.sanitize(pathname), method)
  }

  match(pattern, method) {
    const urlPattern = new URLPattern({
      pathname: pattern,
      baseURL: window.location.origin,
    })

    const currentUrl = location.origin + location.pathname

    if (urlPattern.test(currentUrl) && method) {
      this.loadMethod(method)
    }
  }

  sanitize(pathname) {
    const hasTrailingSlash = pathname.slice(-1) === '/'
    let path
    const length = pathname.length

    if (length === 1) {
      path = pathname
    } else if (hasTrailingSlash) {
      path = pathname.substr(0, length - 1)
    } else {
      path = pathname
    }

    return path
  }
}
